import React,{Component} from 'react';

export class Home extends Component{

    render(){
        return(
            <div className="mt-5 d-flex justify-content-left">
                This is Home page.
            </div>
        )
    }
}